<template>
  <div class="background">
    <svg id="view" ref="view">
        <line v-for="l in lines" v-bind:key="l.id" v-bind:x1="l.x1" v-bind:y1="l.y1" v-bind:x2="l.x2" v-bind:y2="l.y2" />
        
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Background',
  data: function() {
      return {
          lines: []
      }
  },
  mounted: function() {
      this.lines = this.generateLines()
  },
  methods: {
        generateLines: function() {
            var lines = []
            let w = this.$refs.view.clientWidth;
            let h = this.$refs.view.clientHeight;
            for (var i=0; i<100; i++) {
                let x = Math.random() * w;
                let y = Math.random() * h;
                lines.push({
                    id: i,
                    x1: x,
                    y1: y,
                    x2: x + 30,
                    y2: y - 30,
                })

            }
            return lines;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

div {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -100;
}

svg {
    height: 100%;
    width: 100%;
}

line {
    stroke: #ede2eb;
    stroke-width: 1px;
}
</style>
