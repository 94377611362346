<template>
  <div class="service">
      <div v-on:click="toggle()">
      {{ service.name }} version {{ service.version }} last deployed at {{ service.lastdeployed }}
      </div>
      <transition name="bounce">
      <div v-if="expanded">
        <table>
          <tr>
            <td>Description:</td>
            <td>{{ service.description }}</td>
          </tr>
          <tr>
            <td>Maintainer:</td>
            <td>{{ service.maintainer }}</td>
          </tr>
          <tr>
            <td>Runtime:</td>
            <td>{{ service.runtime }}</td>
          </tr>
          <tr>
            <td>Source:</td>
            <td>{{ service.source }}</td>
          </tr>
        </table>
      </div>
      </transition>
  </div>
</template>

// A service contain those fields from the backend response...
// {"services":[
//   {"description":"fleet service",
//   "lastdeployed":"2022-01-13T00:40:26",
//   "maintainer":"odanielson@github.com",
//   "name":"ovl-machinefleet",
//   "namespace":"ovaielo",
//   "runtime":"Google cloudrun ovaielo-192521",
//   "source":"github.com/odanielson/ovl-machinefleet",
//   "version":"0.3"}]}

<script>
export default {
  name: 'Service',
  props: {
    service: {
        type: Object
    }
  },
  data: function() {
    return {
      expanded: false
    }
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.service {
  margin-top: 10px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

table {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  border: 5px dotted #f5ecec;
  border-radius: 10px;
  margin-top: 10px;
}

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
