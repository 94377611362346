<template>
  <div class="productinfo">
    <h1>Ovaielo Machine Fleet</h1>
      <p>This service provides a registry for digital workers</p>
      <p>The purpose is to make it easy to keep a registry of all your deployed compute functionality</p>
      <p>Use it to register anything from physical machines to deployed lambda functions</p>
    <br><br>
    
    <!-- Sign up <a href="/account/sign-up">here</a>! -->
    <h2>API</h2>
    T.B.D
  </div>
</template>

<script>
export default {
  name: 'ProductInfo',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
  margin: 0px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

button {
    margin-left: 10px;
    color: #a98b48;
}
</style>
