<template>
  <div class="services">
      <h2>Services</h2>
      <div v-if="this.authState.user">
        <i>Click on a service to expand it with more info</i>
      <Service v-for="service in services" v-bind:key="service.name" v-bind:service="service"/>
      </div>
      <div v-else>
          Login to see your registered services
    </div>
  </div>
</template>

<script>
import Service from '@/components/Service.vue'

export default {
  name: 'Services',
  components: {
      Service
  },
  created() {
  // Simple GET request using fetch
    if (!this.authState.user) {
      return;
    }
    const requestOptions = {
        method: "GET",
        headers: { "authorization": "Bearer " + this.authState.user.accessToken},
    }
    fetch("/api/2/ovaielo/services", requestOptions)
        .then(response => response.json())
        .then(data => {
          this.services = data.services
        });
  },
  data() {
    return {
      ...this.mapAuthState(),
      "services":[]
      };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
