import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Frontegg } from '@frontegg/vue';

Vue.config.productionTip = false
Vue.config.devtools = false;

Vue.use(Frontegg, {
  contextOptions: {
    baseUrl: 'https://app-zxxyd5rmarn9.frontegg.com',
    clientId: '97465348-bf74-4cfa-af7d-5e7f4e6a9339'
  },
  hostedLoginBox: true,
  router,
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
