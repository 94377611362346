<template>
  <div class="home">
    <ProductInfo/>
    <Services />
  </div>
</template>

<script>
// @ is an alias to /src
import ProductInfo from '@/components/ProductInfo.vue'
import Services from '@/components/Services.vue'

export default {
  name: 'Home',
  components: {
    ProductInfo,
    Services
  }
}
</script>
