<template>
  <div id="app" v-if="fronteggLoaded">
    <div class="status"><b>LABSTATUS</b></div>
    <Background/>
    <p v-if="this.authState.user">
      Logged in as
      {{ this.authState.user.email }}
      <button v-on:click="showAdminPortal">My Account</button>
       <button v-on:click="logout">Logout</button>
    </p>
    <p v-else>
       <button v-if="!this.authState.user" v-on:click="loginWithRedirect">Login</button>
       <!-- <button v-if="!this.authState.user" v-on:click="signup">Sign Up!</button> -->
    </p>
    <div class="window">
    <router-view/>
    </div>
  </div>
</template>

<script>

import Background from './components/Background.vue';
import { mapLoginActions } from "@frontegg/vue";
import { AdminPortal } from "@frontegg/vue";

export default {
  name: "App",
  components: {
    Background
  },
  data() {
    return {
      ...this.mapAuthState(),      
      };
    
  },
  methods: {
    loginWithRedirect: mapLoginActions('loginWithRedirect'),
    logoutWithRedirect: mapLoginActions('logoutWithRedirect'),
    goToLogin() {
      this.$router.push('/account/login');
    },
    logout() {
      this.$router.push('/account/logout');
    },
    signup() {
      this.$router.push('/account/sign-up');

    },
    showAdminPortal() {
      AdminPortal.show();
    },
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #a98b48;
  height: 100%;
}

.status {
  position: absolute;
  padding-left: 20px;
  text-align: left;
  color: white;

}
.window {
  height: 100%;
  padding-left: 30%;
  padding-right: 30%;
}

p {
  padding-top: 10px
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

button {
    margin-left: 10px;
    color: #a98b48;
    border-radius: 10px;
}

Background {
  height: 100%;
}
</style>
